import { Divider, message } from 'antd';
import { useAtom } from 'jotai';
import React from 'react';
import { paymentMethods } from '../../helpers/config';
import {
    deletePaymentAtom, updatePaymentAtom, paymentsAtom, getTotalPaymentsAtom,
    getBillSubTotalAtom, getCompleteBillAtom, resetBillAtom
} from '../../helpers/state/bill';
import { cedisLocale } from '../../helpers/utilities';
import smallTalk from 'smalltalk';
import { useMutation } from 'react-query';
import { postBill } from '../../helpers/api';
import _ from 'lodash';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import { getUser } from '../../helpers/auth';

export default function CheckOutComponent ( { onUpdate } ) {
    const [ payments, setPayments ] = useAtom( paymentsAtom );
    const [ totalPayments ] = useAtom( getTotalPaymentsAtom );
    const [ totalAmount ] = useAtom( getBillSubTotalAtom );

    const [ , updatePayments ] = useAtom( updatePaymentAtom );
    const [ , deletePayments ] = useAtom( deletePaymentAtom );
    const [ completeBill ] = useAtom( getCompleteBillAtom );
    const [ , resetBill ] = useAtom( resetBillAtom );

    const totalDue = parseFloat( totalAmount - ( completeBill.bill.discount || 0 ) );
    const balance = parseFloat( totalDue - totalPayments );

    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        size: ""
    } );

    const { mutateAsync: CommitBill, isLoading } = useMutation( ( data ) => postBill( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 201 ) {
                message.success( data.data.message );
                resetBill();
                onUpdate( data.data.data ); //mute if no listerner or caller
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );



    const handleCheckOut = () => {
        // check for line items
        if ( completeBill.items.length === 0 ) {
            message.error( 'No services detected' );
            return;
        }

        // check for payment methods
        if ( completeBill.payments.length === 0 ) {
            message.error( 'No payments found' );
            return;
        }

        // check for customer if there is balance
        if ( totalPayments < totalAmount && !completeBill.bill.patientId ) {
            message.error( 'A client/patient is required for incomplete payments' );
            return;
        }

        if ( completeBill.items.filter( item => !item.quantity ).length > 0 ) {
            message.error( 'Invalid service quantities' );
            return;
        }

        if ( completeBill.items.filter( item => !item.serviceId ).length > 0 ) {
            message.error( 'empty service(s) not allowed' );
            return;
        }

        if ( completeBill.payments.filter( pay => !pay.paymentMethod ).length > 0 ) {
            message.error( 'Invalid payment method(s)' );
            return;
        }

        // process sale
        smallTalk.confirm(
            "Commit Bill", "You are about to commit a transaction, continue?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },

        }
        ).then( go => {
            CommitBill( completeBill );
        } ).catch( ex => {
            return false;
        } );
    };

    return (
        <div>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                size={ modal.size || 'md' }
                zIndex={ 100 }
            >
                { modal.content }
            </Modal>

            <div>
                <div className="row">
                    <div className="col-6">
                        <div >
                            <div>Amount Due</div>
                            <h4 className='alert alert-danger'>
                                { cedisLocale.format( totalDue ) }
                            </h4>
                        </div>
                    </div>
                    <div className="col-6">
                        <div >
                            <div>Amount Paid</div>
                            <h4 className='alert alert-success'>{ cedisLocale.format( totalPayments ) }</h4>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={ `alert alert-${ balance > 0 ? 'danger' : balance < 0 ? 'success' : 'secondary' }` }>
                            <div>
                                {
                                    balance > 0 ? 'Client owes' : balance < 0 ? 'Pay customer the balance below' : 'Perfection 😊'
                                }
                            </div>
                            <h1 className='mb-0'> { cedisLocale.format( balance ) }</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Divider orientation='left' orientationMargin={ 0 } className="mb-1">Payment Methods</Divider>
                {
                    payments.map( ( pay, index ) =>
                        <PaymentMethod
                            usedMethods={ payments }
                            payment={ pay }
                            key={ index }
                            onUpdate={ updatePayments }
                            onDelete={ deletePayments }
                        /> )
                }
                {
                    totalAmount > 0 &&
                    <button onClick={ setPayments } className="button is-small">
                        <span className="bi bi-plus-circle me-2"></span>
                        Add Payment
                    </button>
                }
            </div>


            <div>
                <Divider />
                <button
                    disabled={ totalAmount === 0 }
                    onClick={ handleCheckOut }
                    className={ `${ isLoading && 'is-loading ' } is-large button w-100 app-btn  btn-prim` }>
                    <span className="bi bi-check-circle me-2"></span>
                    <small> Check Out</small>
                </button>
            </div>
        </div>
    );
}



export const PaymentMethod = ( { usedMethods, payment, onUpdate, onDelete } ) => {

    return (
        <div className="row align-items-center mb-2">
            <div className="col-5">
                <select
                    value={ payment.paymentMethod }
                    onChange={ e => onUpdate( { recordId: payment.recordId, field: 'paymentMethod', value: e.target.value } ) }
                    className='input w-100'
                >
                    <option value="" selected disabled hidden>Choose method</option>
                    {
                        paymentMethods?.map( mth => <option value={ mth.value } key={ mth.value }>{ mth.title }</option> )
                    }
                </select>
            </div>
            <div className="col-5">
                <input
                    value={ payment.amount }
                    onChange={ e => onUpdate( { recordId: payment.recordId, field: 'amount', value: e.target.value } ) }
                    type="number"
                    className="input"
                    placeholder='amount paid' step="0.01" />
            </div>
            <div className="col-1 g-0">
                <button className='button' onClick={ () => onDelete( payment.recordId ) }>
                    <span className="bi bi-trash text-danger"></span>
                </button>
            </div>
        </div>
    );
};
