// import SaleReceipt from './receipt';

import { Divider, Modal as AntModal, message } from 'antd';
import { Loader, Modal, Select, Skeleton } from '@mantine/core';
import { useState } from 'react';
import LineItemsComponent from './bill-items-component';
import { cedisLocale } from '../../helpers/utilities';

import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { getAllPatients, getAllServices, getBill, putBill } from '../../helpers/api';

import NewPatientForm from '../../pages/catalog/components/new-patient';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import EditPayments from './edit-payment';
import { useAtom } from 'jotai';
import { billPaymentsAtom, setBillPayments } from '../../helpers/state/edit-bill';
import smallTalk from 'smalltalk';
import { IconPrinter } from '@tabler/icons';
import BillReceipt from './receipt';


const EditBillForm = ( {
    billId,
    onUpdate
} ) => {
    // states
    const [ state, setState ] = useState( {} );
    const [ serverServices, setServerServices ] = useState( [] );
    const [ items, setItems ] = useState( [] );
    // const [ payments, setPayments ] = useState( [] );

    // meta states
    const [ show, setShow ] = useState( { note: false, discount: false } );

    // atom
    const [ payments, addPayment ] = useAtom( billPaymentsAtom );
    const [ , setPayments ] = useAtom( setBillPayments );


    // consts
    let subTotal = items?.reduce( ( sum, { rate, quantity } ) => ( sum + ( parseFloat( rate ) * parseInt( quantity ) ) ), 0 );
    let totalPayments = payments?.reduce( ( sum, { amount } ) => sum + parseFloat( amount ), 0 );
    let totalDue = parseFloat( subTotal - ( state?.discount || 0 ) );
    let balance = parseFloat( ( totalDue || 0 ) - ( totalPayments || 0 ) );

    // queries
    const { data: patients, isFetching: fetchingPatients, refetch: fetchPatients } = useQuery( {
        queryFn: getAllPatients,
        queryKey: [ 'patients' ],
    } );

    const { isFetching: fetchingServices, refetch: fetchServices } = useQuery( {
        queryFn: getAllServices,
        queryKey: [ 'services' ],
        onSuccess: data => setServerServices( data )
    } );

    const { isFetching, refetch: fetchBill } = useQuery( {
        queryFn: () => getBill( billId ),
        queryKey: [ 'bill', billId ],
        onSuccess: data => {
            setState( {
                ..._.omit( data, [ 'items', 'payments', 'consultation', 'patient', 'createdAt' ] ),
                patientId: data?.patient.id,
                consultationId: data?.consultation?.id || null
            } );

            const cleanedItems = data?.items?.map( item => {
                return {
                    recordId: nanoid(),
                    serviceId: item?.service?.id,
                    quantity: item?.quantity,
                    rate: item?.rate,
                    taxRate: item?.taxRate,
                };
            } );

            const cleanedPayments = data?.payments?.map( pay => {
                return {
                    recordId: nanoid(),
                    paymentMethod: pay?.paymentMethod,
                    amount: pay?.amount,
                };
            } );

            setItems( cleanedItems || [] );
            setPayments( cleanedPayments || [] );
            // console.log( { state, cleanedPayments, cleanedItems } );
        }
    } );


    const { mutateAsync: updateBill, isLoading } = useMutation( ( data ) => putBill( data ), {
        onSuccess: ( data, variables, context ) => {
            if ( data.status === 200 ) {
                message.success( data.data.message );
                onUpdate( data.data.data );
                return;
            }

            throw data;
        },
        onError: ( error, variables, context ) => {
            const err = error.response.data.message;
            if ( _.isArray( err ) ) {
                err.map( err =>
                    message.error( err.message )
                );
            }
            else {
                message.error( err );
            }
        },
        retry: true
    } );



    // handlers
    const handleAdditem = () => {
        setItems( [
            ...items,
            {
                recordId: nanoid(),
                serviceId: null,
                quantity: 1,
                rate: 0,
            }
        ] );
    };


    const handleUpdateItem = ( params ) => {
        let { recordId, field, value } = params;

        let item = items.find( pr => pr.recordId === recordId );

        if ( field === 'quantity' && value < 0 )
            value = 0;

        if ( ( field === 'serviceId' ) && items.find( itm => itm.serviceId === value ) ) {
            message.error( 'already selected!' );
            return;
        }

        if ( field === 'serviceId' )
            item.rate = serverServices.find( s => s.id === value )?.baseRate;

        item[ field ] = value;
        setItems( items.map( itm => {
            if ( itm.recordId === recordId )
                return item;

            return itm;
        } ) );
    };

    const handleDeleteItem = ( recordId ) => setItems( items?.filter( itm => itm.recordId != recordId ) );


    const handleUpdateBill = () => {
        console.log( { state, items, payments } );

        if ( items.length === 0 ) {
            message.error( 'No services detected' );
            return;
        }

        // check for payment methods
        if ( payments.length === 0 ) {
            message.error( 'No payments found' );
            return;
        }

        // check for customer if there is balance
        if ( totalPayments < totalDue && !state.patientId ) {
            message.error( 'A client/patient is required for incomplete payments' );
            return;
        }

        if ( items.filter( item => !item.quantity ).length > 0 ) {
            message.error( 'Invalid service quantities' );
            return;
        }

        if ( items.filter( item => !item.serviceId ).length > 0 ) {
            message.error( 'empty service(s) not allowed' );
            return;
        }

        if ( payments.filter( pay => !pay.paymentMethod ).length > 0 ) {
            message.error( 'Invalid payment method(s)' );
            return;
        }

        if ( payments.filter( pay => pay.amount <= 0 ).length > 0 ) {
            message.error( 'Invalid payment amount(s)' );
            return;
        }

        // process sale
        smallTalk.confirm(
            "Update Bill", "Continue updating this bill?", {
            buttons: {
                ok: 'YES',
                cancel: 'NO',
            },

        }
        ).then( go => {
            updateBill( { bill: state, payments, items } );
        } ).catch( ex => {
            return false;
        } );

    };


    // others
    const [ modal, setModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        size: "",
        zIndex: 60
    } );
    const [ antModal, setAntModal ] = useState( {
        isOpen: false,
        title: '',
        content: null,
        width: ""
    } );


    return (
        <div className='row sticky-bottom'>
            <Modal
                onClose={ () => setModal( { ...modal, isOpen: false } ) }
                opened={ modal.isOpen }
                title={ modal.title }
                size={ modal.size || 'md' }
                zIndex={ 55 }
            >
                { modal.content }
            </Modal>
            <AntModal
                open={ antModal.isOpen }
                onCancel={ () => setAntModal( { ...antModal, isOpen: false } ) }
                title={ antModal.title }
                width={ antModal.width }
                zIndex={ 60 }
                footer={ false }
            >
                { antModal.content }
            </AntModal>
            {
                !isFetching &&
                // <Skeleton className='d-inline'>loading services</Skeleton> :
                <div className="col-md-8 col-12">
                    <div className="row">
                        <div className="col-5 fw-bold">Service Name</div>
                        <div className="col-2 fw-bold">Rate</div>
                        <div className="col-2 fw-bold">Quantity</div>
                        <div className="col-3 fw-bold">Line Total</div>
                    </div>
                    <Divider className='mb-0' />
                    {
                        items?.length > 0 &&
                        <table className='table stripped table-hover'>
                            <tbody>
                                {
                                    items.map( ( item, i ) =>
                                        <tr>
                                            <LineItemsComponent
                                                item={ item }
                                                services={ serverServices }
                                                onUpdate={ handleUpdateItem }
                                                onDelete={ handleDeleteItem } />
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    }
                    <button onClick={ handleAdditem } className={ `button app-btn btn-prim fw-bold ${ fetchingServices && ' is-loading' }` }>
                        <span className='bi bi-plus-circle me-2' />
                        Add Bill Item
                    </button>
                    {
                        ( items.length > 0 ) &&
                        <button
                            className='button app-btn is-ghost px-2 fw-bold ms-2'
                            onClick={ () => setItems( [] ) }
                        >
                            <span className="bi bi-trash me-2"></span>
                            clear
                        </button>
                    }
                </div> }
            {
                isFetching ?
                    <Loader className='d-inline' /> :
                    <div className="col-md-4 col-12 mt-5 mt-md-0">
                        <div className="d-flex justify-content-between">
                            <h3>
                                { state?.billNumber }
                            </h3>
                            <button
                                onClick={ () =>
                                    setModal( {
                                        isOpen: true,
                                        title: "Receipt",
                                        size: 300,
                                        content: <BillReceipt param='billId' query={ state.id } />
                                    } ) }
                                className="ms-3 button app-btn">
                                <IconPrinter className="me-2" />
                                <strong>Receipt</strong>
                            </button>
                            <p className='text-muted'>date x time</p>
                        </div>
                        {/* customer selection section */ }
                        <div className="field mb-0">
                            <label htmlFor="patientId">Patient / Client</label>
                            {
                                fetchingPatients ?
                                    <Skeleton>loading</Skeleton> :
                                    <Select
                                        id='patientId'
                                        value={ state.patientId }
                                        nothingFound="No match"
                                        onChange={ ( value ) => setState( { ...state, patientId: value } ) }
                                        size="md"
                                        clearable
                                        searchable
                                        placeholder='select patient / client'
                                        data={
                                            patients?.map( pa => {
                                                return {
                                                    label: `${ pa.firstName } ${ pa.lastName }`,
                                                    value: pa.id
                                                };
                                            } )
                                        }
                                    />
                            }
                        </div>
                        <p
                            className='text-muted mt-1 hover-hand'
                            onClick={ () => setModal( {
                                isOpen: true,
                                title: 'Add Client/Patient',
                                content: <NewPatientForm
                                    showCharm={ false }
                                    showBottomButtons
                                    onSuccess={ fetchPatients }
                                />,
                            } ) }
                        >
                            <span className="bi bi-plus-circle me-2"></span>
                            add patient / client
                        </p>
                        {
                            show.discount &&
                            <div className="field">
                                <label htmlFor="discount">Discount</label>
                                <input
                                    type="number"
                                    id='discount'
                                    value={ state?.discount }
                                    onChange={ e => setState( { ...state, discount: e.target.value } ) }
                                    className="input"
                                    placeholder='add discount here'
                                />
                            </div>

                        }

                        {/* <Divider /> */ }
                        {
                            items.length > 0 &&
                            <>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <strong>EDIT</strong>
                                    </div>
                                    <div>
                                        <button className='button app-btn fw-bold is-ghost px-2'
                                            title='add notes to this bill'
                                            onClick={ () => setModal( {
                                                title: 'Update Payments',
                                                isOpen: true,
                                                size: 400,
                                                zIndex: 60,
                                                content: <EditPayments />
                                            } ) }
                                        >
                                            {
                                                payments?.length > 0 &&
                                                <span className="bi bi-info-circle-fill text-success me-1"></span>
                                            }
                                            Payments
                                        </button>
                                        <button className='button app-btn fw-bold is-ghost px-2'
                                            title='add notes to this bill'
                                            onClick={ () => setShow( { ...show, discount: !show.discount } ) }
                                        >
                                            {
                                                state?.discount > 0 &&
                                                <span className="bi bi-info-circle-fill text-success me-1"></span>
                                            }
                                            { show.discount > 0 && 'Hide ' }
                                            Discount
                                        </button>
                                        <button className='button app-btn fw-bold is-ghost px-2'
                                            title='add notes to this bill'
                                            onClick={ () => setShow( { ...show, note: !show.note } ) }
                                        >
                                            {
                                                state?.note &&
                                                <span className="bi bi-info-circle-fill text-success me-1"></span>
                                            }
                                            { show.note ? 'Hide ' : '' }
                                            Notes
                                        </button>
                                    </div>
                                </div>
                                <Divider className='my-2' />
                            </>
                        }
                        {
                            show.note &&
                            <textarea
                                value={ state.note }
                                onChange={ e => setState( { ...state, note: e.target.value } ) }
                                name="notes"
                                className='textarea mb-2'
                                placeholder='bill comments/notes here'
                                rows={ 2 }
                                id="notes" />
                        }
                        {
                            state?.discount > 0 &&
                            <div className='d-flex justify-content-between mb-2'>
                                <strong>Discount { `(${ ( ( state?.discount / subTotal ) * 100 ).toPrecision( 2 ) }%)` }</strong>
                                <strong>{ cedisLocale.format( state.discount ) }</strong>
                            </div>
                        }
                        <div className='d-flex justify-content-between'>
                            <strong>Subtotal</strong>
                            <strong>{ cedisLocale.format( subTotal ) }</strong>
                        </div>
                        {
                            state?.discount > 0 &&
                            <>
                                <Divider />
                                <div className='d-flex justify-content-between'>
                                    <strong>Total Due:</strong>
                                    <strong>{ cedisLocale.format( totalDue ) }</strong>
                                </div>
                            </>
                        }
                        {/* <div className='d-flex justify-content-between'>
                            <strong>Subtotal</strong>
                            <strong>{ cedisLocale.format( subTotal ) }</strong>
                        </div> */}
                        <div className='d-flex justify-content-between my-2'>
                            <strong>Paid</strong>
                            <strong>{ cedisLocale.format( totalPayments ) }</strong>
                        </div>
                        <div className={ `d-flex justify-content-between` }>
                            <strong>Balance</strong>
                            <h4 className={ `fw-bold ${ balance != 0 ? ' text-danger' : ' text-success' }` }>{ cedisLocale.format( balance ) }</h4>
                        </div>
                        <Divider />
                        <button
                            onClick={ handleUpdateBill }
                            disabled={ items.length < 1 }
                            className={ `button h6 py-4 w-100 app-btn ${ totalPayments > 0 && ' btn-prim ' } ${ isLoading && ' is-loading' } d-flex justify-content-between` }>
                            <div className='fw-bold'>
                                UPDATE
                                <small className='ms-2'>{ items?.length || 0 } services</small></div>
                            <div>GHS { cedisLocale.format( totalPayments > 0 ? totalPayments : subTotal ) }</div>
                        </button>
                    </div > }
        </div>
    );
};

export default EditBillForm;;